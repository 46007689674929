import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { Ref } from "vue";

import { ExistingPushMessage } from "@/api/pushnotifications/pushmessage/typedefs";

import MessageActivateDialog from "./MessageActivateDialog.vue";

export function useMessageActivateDialog() {
  const $q = useQuasar();

  const openDialog = (message: Ref<ExistingPushMessage>) =>
    $q.dialog({
      component: MessageActivateDialog,
      componentProps: {
        message: message.value,
      },
    });

  return {
    openDialog,
  };
}
