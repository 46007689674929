
import { defineComponent, onBeforeUnmount } from "@vue/runtime-core";
import useDialogPluginComponent from 'quasar/src/composables/use-dialog-plugin-component.js';;
import { computed, PropType, Ref, ref } from "vue";

import { ExistingPushMessage } from "@/api/pushnotifications/pushmessage/typedefs";
import { useIFrameContentResizeObserver } from "@/composables/useIFrameContentResizeObserver";
import { convertIFrameMessageData, MessageType } from "@/lib/iframe";

export default defineComponent({
  props: {
    message: {
      required: true,
      type: Object as PropType<ExistingPushMessage>,
    },
  },
  emits: Array.from(useDialogPluginComponent.emits),
  setup(props) {
    const iframeRef: Ref<HTMLIFrameElement | null> = ref(null);

    const { dialogRef, onDialogOK, onDialogHide } = useDialogPluginComponent();
    const { attachObserver } = useIFrameContentResizeObserver({
      iframeRef,
      observedElementSelector: ".active",
    });

    const eventHandler = (event: MessageEvent<unknown>) => {
      const data = convertIFrameMessageData(event.data);
      switch (data?.type) {
        case MessageType.POPUP_CREATED: {
          attachObserver();
          break;
        }
        case MessageType.POPUP_CANCEL_CLICKED: {
          onDialogHide();
          break;
        }
        case MessageType.POPUP_OK_CLICKED: {
          onDialogOK();
          break;
        }
      }
    };

    const iframeSrc = computed(() => {
      return `/pushnotifications/message/activate-popup/${props.message.id}`;
    });

    window.addEventListener("message", eventHandler);

    onBeforeUnmount(() => {
      window.removeEventListener("message", eventHandler);
    });

    return {
      dialogRef,
      iframeRef,
      iframeSrc,
      onDialogOK,
      onDialogHide,
    };
  },
});
