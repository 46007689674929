import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-789694a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref: "dialogRef",
    "data-test": "pushmessage.activatedialog",
    onHide: _ctx.onDialogHide
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { class: "dialog-card q-dialog-plugin" }, {
        default: _withCtx(() => [
          _createElementVNode("iframe", {
            ref: "iframeRef",
            src: _ctx.iframeSrc,
            frameborder: "0"
          }, " ", 8, _hoisted_1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onHide"]))
}