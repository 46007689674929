import camelcaseKeys from "camelcase-keys";

import { convertImage } from "@/api/image/converters";
import {
  DeliveryOptions,
  DeliveryOptionsRecurring,
  DeliveryOptionsScheduled,
  DeliveryOptionsTriggered,
  DeliveryTriggeredTriggerGuard,
  DeliveryType,
  DeliveryTypeGuard,
  MessageDeliveryMetadata,
  MessageDeliveryMetadataGuard,
} from "@/api/pushnotifications/messagedelivery/typedefs";
import { convertTags } from "@/api/tags/list";
import { castBoolean, castNumber, castString } from "@/lib/casts";
import {
  isDateStringValidFormat,
  isDateTimeStringValidFormat,
  isTimeStringValidFormat,
} from "@/lib/dateTime";
import { httpClient, throwIfBadResponse } from "@/lib/http";
import { cast } from "@/lib/typing";

import { convertMessageTarget } from "./target";
import {
  ExistingPushMessage,
  MessageStatus,
  MessageStatusGuard,
} from "./typedefs";

export const statusOptions: MessageStatus[] = [
  "Draft",
  "Deleted",
  "Pending",
  "Active",
  "Paused",
  "Cancelled",
  "Failed",
  "Finished",
];

function convertMessageStatus(data: unknown): MessageStatus {
  if (!data) {
    throw new Error("Message with missing status");
  }

  return cast(MessageStatusGuard, data);
}

function convertDeliveryOptionsRecurring(
  rawOptions: unknown
): DeliveryOptionsRecurring {
  const optionsData = rawOptions as {
    time?: unknown;
    interval?: unknown;
    start_datetime?: unknown;
    end_datetime?: unknown;
  };

  const rawTime = optionsData["time"];
  if (typeof rawTime !== "string" || !isTimeStringValidFormat(rawTime)) {
    throw new Error("Invalid time string.");
  }

  const rawInterval = optionsData["interval"];
  if (typeof rawInterval !== "number") {
    throw new Error("Invalid interval.");
  }

  const rawStartDate = optionsData["start_datetime"];
  const rawEndDate = optionsData["end_datetime"];
  if (
    typeof rawStartDate !== "string" ||
    typeof rawEndDate !== "string" ||
    !isDateStringValidFormat(rawStartDate) ||
    !isDateStringValidFormat(rawEndDate)
  ) {
    throw new Error("Invalid date range.");
  }

  return {
    time: rawTime,
    interval: rawInterval,
    dateRange: {
      from: rawStartDate,
      to: rawEndDate,
    },
  };
}

function convertDeliveryOptionsScheduled(
  data: unknown
): DeliveryOptionsScheduled {
  const optionData = data as {
    date_time?: unknown;
  };

  const rawDateTime = optionData["date_time"];

  if (
    typeof rawDateTime !== "string" ||
    !isDateTimeStringValidFormat(rawDateTime)
  ) {
    throw new Error("Failed to convert recurring options datetime.");
  }

  return {
    dateTime: rawDateTime,
  };
}

function convertDeliveryOptionsTriggered(
  data: unknown
): DeliveryOptionsTriggered {
  const optionData = data as {
    delay_seconds?: unknown;
    allow_show_if_app_foreground?: unknown;
    trigger?: unknown;
    start_datetime?: unknown;
    end_datetime?: unknown;
  };
  const delaySeconds = optionData["delay_seconds"];
  if (typeof delaySeconds !== "number") {
    throw new Error("Invalid delay_seconds variable type.");
  }

  const allowShowIfAppForeground = optionData["allow_show_if_app_foreground"];
  if (typeof allowShowIfAppForeground !== "boolean") {
    throw new Error("Invalid allow_show_if_app_foreground variable type.");
  }

  const trigger = optionData["trigger"];
  if (typeof trigger !== "string") {
    throw new Error("Invalid trigger variable type.");
  }

  const startDateTime = optionData["start_datetime"];

  if (
    startDateTime !== null &&
    (typeof startDateTime !== "string" ||
      !isDateTimeStringValidFormat(startDateTime))
  ) {
    throw new Error("Failed to convert startDateTime to datetime.");
  }

  const endDateTime = optionData["end_datetime"];

  if (
    typeof endDateTime !== "string" ||
    !isDateTimeStringValidFormat(endDateTime)
  ) {
    throw new Error("Failed to convert endDateTime to datetime.");
  }

  return {
    delaySeconds: delaySeconds,
    allowShowIfAppForeground: allowShowIfAppForeground,
    trigger: cast(DeliveryTriggeredTriggerGuard, trigger),
    startDateTime: startDateTime,
    endDateTime: endDateTime,
  };
}

function convertDeliveryOptions(
  deliveryType: DeliveryType,
  rawOptions: unknown
): DeliveryOptions {
  if (deliveryType === DeliveryType.RECURRING) {
    return convertDeliveryOptionsRecurring(rawOptions);
  } else if (deliveryType === DeliveryType.SCHEDULED) {
    return convertDeliveryOptionsScheduled(rawOptions);
  } else if (deliveryType === DeliveryType.TRIGGERED) {
    return convertDeliveryOptionsTriggered(rawOptions);
  } else {
    return {};
  }
}

function convertMessageDelivery(data: unknown): MessageDeliveryMetadata {
  const deliveryData = data as {
    id?: unknown;
    delivery_time?: unknown;
    estimated_users?: unknown;
    messages_sent?: unknown;
    status?: unknown;
  };

  // TODO(PNS-1738): Drop when BE will start sending camelCase.
  const camelCasedData = camelcaseKeys(deliveryData);
  return cast(MessageDeliveryMetadataGuard, camelCasedData);
}

function convertMessageDeliveries(data: unknown): MessageDeliveryMetadata[] {
  if (!Array.isArray(data)) {
    throw new Error("Message deliveries data was not a list.");
  }

  return data.map((item) => convertMessageDelivery(item));
}

function convertDeliveryType(data: unknown) {
  return cast(DeliveryTypeGuard, data);
}

export function convertPushMessage(data: unknown): ExistingPushMessage {
  const messageData = data as {
    created_at?: unknown;
    created_by?: unknown;
    delivery_type?: unknown;
    deliveries?: unknown;
    dry_run?: unknown;
    id?: unknown;
    image?: unknown;
    message?: unknown;
    modified_at?: unknown;
    modified_by?: unknown;
    name?: unknown;
    status?: unknown;
    title?: unknown;
    target?: unknown;
    url?: unknown;
    campaign_id?: unknown;
    redirector_stats_url?: unknown;
    tags?: unknown;
  };

  const deliveryType = convertDeliveryType(messageData?.["delivery_type"]);

  const deliveryOptions = convertDeliveryOptions(deliveryType, messageData);

  messageData["tags"] = messageData?.["tags"];

  const tags = convertTags(messageData?.["tags"]);

  return {
    id: castNumber(messageData?.["id"]),
    name: castString(messageData?.["name"]),
    title: castString(messageData?.["title"]),
    text: castString(messageData?.["message"]),
    url: messageData?.["url"] ? castString(messageData?.["url"]) : null,
    tags,
    campaignId: Number.isInteger(messageData?.["campaign_id"])
      ? castNumber(messageData?.["campaign_id"])
      : null,
    image: messageData?.["image"] ? convertImage(messageData?.["image"]) : null,
    target: messageData?.["target"]
      ? convertMessageTarget(messageData?.["target"])
      : null,
    createdBy: castString(messageData?.["created_by"]),
    createdAt: castString(messageData?.["created_at"]),
    modifiedBy: messageData?.["modified_by"]
      ? castString(messageData?.["modified_by"])
      : null,
    modifiedAt: messageData?.["modified_at"]
      ? castString(messageData?.["modified_at"])
      : null,
    deliveryType,
    deliveryOptions,
    deliveries: convertMessageDeliveries(messageData?.["deliveries"]),
    status: convertMessageStatus(messageData?.["status"]),
    isDryRun: castBoolean(messageData?.["dry_run"]),
    redirectorStatsUrl: messageData?.["redirector_stats_url"]
      ? castString(messageData?.["redirector_stats_url"])
      : null,
  };
}

export async function fetchPushMessage(
  productLine: number,
  id: number
): Promise<ExistingPushMessage> {
  const response = await httpClient.get(
    `/api/pushnotifications/product-lines/${productLine}/messages/${id}/`
  );

  await throwIfBadResponse(response);

  const result = await response.json();

  return convertPushMessage(result);
}
