import * as t from "io-ts";

import { ImageMetadataGuard } from "@/api/image/typedefs";
import {
  DeliveryOptionsGuard,
  DeliveryType,
  DeliveryTypeGuard,
  MessageDeliveryMetadataGuard,
} from "@/api/pushnotifications/messagedelivery/typedefs";
import { TagGuard } from "@/api/tags/typedefs";
import { TargetUnifiedGuard } from "@/api/target/typedefs";

// TODO(PNS-2180): Extract to shared library.
export const MessageTargetGuard = TargetUnifiedGuard;

export type MessageTarget = t.TypeOf<typeof MessageTargetGuard>;

export const MessageStatusGuard = t.keyof({
  Draft: null,
  Deleted: null,
  Pending: null,
  Active: null,
  Paused: null,
  Cancelled: null,
  Failed: null,
  Finished: null,
});

export type MessageStatus = t.TypeOf<typeof MessageStatusGuard>;

export const NewPushMessageGuard = t.type({
  name: t.string,
  title: t.string,
  text: t.string,
  url: t.union([t.string, t.null]),
  campaignId: t.union([t.number, t.null]),
  image: t.union([ImageMetadataGuard, t.null]),
  target: t.union([MessageTargetGuard, t.null]),
  deliveryType: t.union([DeliveryTypeGuard, t.null]),
  deliveryOptions: DeliveryOptionsGuard,
  isDryRun: t.boolean,
  tags: t.array(TagGuard),
});

export type NewPushMessage = t.TypeOf<typeof NewPushMessageGuard>;
// NewPushMessage type is used to initialize form with default values. Not all
// these values are legal to be sent to API.
export type NewPushMessageComplete = NewPushMessage & {
  deliveryType: DeliveryType;
};

export const ExistingPushMessageGuard = t.intersection([
  NewPushMessageGuard,
  t.type({
    id: t.number,
    createdBy: t.string,
    createdAt: t.string,
    modifiedBy: t.union([t.string, t.null]),
    modifiedAt: t.union([t.string, t.null]),
    deliveries: t.array(MessageDeliveryMetadataGuard),
    deliveryType: DeliveryTypeGuard,
    status: MessageStatusGuard,
    redirectorStatsUrl: t.union([t.string, t.null]),
  }),
]);

export type ExistingPushMessage = t.TypeOf<typeof ExistingPushMessageGuard>;

export type PushMessage =
  | NewPushMessage
  | NewPushMessageComplete
  | ExistingPushMessage;

export function isExistingPushMessage(
  pushMessage: PushMessage
): pushMessage is ExistingPushMessage {
  return (pushMessage as ExistingPushMessage).id !== undefined;
}
