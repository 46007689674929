import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { computed, ref, Ref } from "vue";
import { useRouter } from "vue-router";

import { isDeliveryOptionsScheduled } from "@/api/pushnotifications/messagedelivery/typedefs";
import { activatePushMessage } from "@/api/pushnotifications/pushmessage/activate";
import { cancelPushMessage } from "@/api/pushnotifications/pushmessage/cancel";
import { castPushMessageValid } from "@/api/pushnotifications/pushmessage/casts";
import { createPushMessage } from "@/api/pushnotifications/pushmessage/create";
import { deletePushMessage } from "@/api/pushnotifications/pushmessage/delete";
import { pushMessagePreview } from "@/api/pushnotifications/pushmessage/preview";
import { fetchMessageTarget } from "@/api/pushnotifications/pushmessage/target";
import {
  ExistingPushMessage,
  isExistingPushMessage,
  PushMessage,
} from "@/api/pushnotifications/pushmessage/typedefs";
import { updatePushMessage } from "@/api/pushnotifications/pushmessage/update";
import { requestPushMessageValidation } from "@/api/pushnotifications/pushmessage/validate";
import { ValidationStatus } from "@/api/target/typedefs";
import { useActiveMessageSubmitDialog } from "@/components/pushmessages/dialogs/useActiveMessageSubmitDialog";
import { useMessageActivateDialog } from "@/components/pushmessages/dialogs/useMessageActivateDialog";
import { useMessageCancelDialog } from "@/components/pushmessages/dialogs/useMessageCancelDialog";
import { useMessageDeleteDialog } from "@/components/pushmessages/dialogs/useMessageDeleteDialog";
import { useCloneDialog } from "@/components/shared/clonedialog/useCloneDialog";
import { getErrorMessage } from "@/lib/errors";
import { getProductLine } from "@/lib/productLine";
import {
  ChangedField,
  FieldToCheck,
  UsePushMessageActions,
} from "@/views/pushmessages/typedefs";

function getChangeDeliveryOptionsFieldAsString(
  pushMessage: PushMessage
): string {
  const result = pushMessage.deliveryOptions;
  if (isDeliveryOptionsScheduled(result)) {
    return result.dateTime ?? "";
  }

  return "";
}

function getChangedFieldAsString(
  pushMessage: PushMessage,
  fieldToCheck: FieldToCheck
): string {
  switch (fieldToCheck) {
    case "image": {
      const result = pushMessage[fieldToCheck];
      return result?.url ?? "";
    }
    case "deliveryOptions": {
      return getChangeDeliveryOptionsFieldAsString(pushMessage);
    }
    case "tags": {
      const result = pushMessage[fieldToCheck];
      return result?.map((item) => item.label).join(",") ?? "";
    }
  }
  return pushMessage[fieldToCheck]?.toString() ?? "";
}

function getChangedFieldsForActiveMessage(
  oldValue: PushMessage,
  newValue: PushMessage
): ChangedField[] {
  const changedFields: ChangedField[] = [];
  const fieldsToCheck: FieldToCheck[] = [
    "title",
    "text",
    "image",
    "url",
    "campaignId",
    "deliveryOptions",
    "tags",
  ];

  for (const fieldToCheck of fieldsToCheck) {
    const oldValueString = getChangedFieldAsString(oldValue, fieldToCheck);
    const newValueString = getChangedFieldAsString(newValue, fieldToCheck);
    if (oldValueString !== newValueString) {
      changedFields.push({
        name: fieldToCheck,
        valueBefore: oldValueString,
        valueAfter: newValueString,
      });
    }
  }
  return changedFields;
}

export const usePushMessageActions: UsePushMessageActions = ({
  isLoading,
  productLineId,
  cloneProductLineOptions,
  pushMessage,
  notEditedPushMessage,
  onTriggerValidation,
  onPushMessageReset,
  onPushMessageSubmitted,
  onValidationStatusChange,
  reloadPushMessage,
}) => {
  const $q = useQuasar();
  const router = useRouter();

  const isEditingActivePushMessage = ref(false);

  const onSubmitAction = async () => {
    isLoading.value = true;
    if (isExistingPushMessage(pushMessage.value)) {
      try {
        onPushMessageSubmitted(
          await updatePushMessage(productLineId.value, pushMessage.value)
        );
      } catch (error: unknown) {
        $q.notify({ type: "negative", message: getErrorMessage(error) });
        isLoading.value = false;
        return;
      }

      if (pushMessage.value.target !== null) {
        try {
          const target = await fetchMessageTarget(
            productLineId.value,
            pushMessage.value.target.id
          );
          onValidationStatusChange(target.status);
        } catch (error: unknown) {
          $q.notify({
            type: "negative",
            message: getErrorMessage(error),
          });
          isLoading.value = false;
          return;
        }
      }
    } else {
      try {
        const pushMessageValid = castPushMessageValid(pushMessage.value);
        const result = await createPushMessage(
          productLineId.value,
          pushMessageValid
        );
        router.push({
          name: "PushMessage",
          params: {
            productLine: productLineId.value,
            product: getProductLine(productLineId.value).productId,
            pushMessageId: result.id,
          },
        });
      } catch (error: unknown) {
        $q.notify({ type: "negative", message: String(error) });
        isLoading.value = false;
        return;
      }
    }

    isLoading.value = false;
  };

  const onSubmitActivePushMessageAction = (): void => {
    const changedFields = getChangedFieldsForActiveMessage(
      notEditedPushMessage.value,
      pushMessage.value
    );
    const { openDialog: openActiveSubmitDialog } = useActiveMessageSubmitDialog(
      {
        onOk: async () => {
          await onSubmitAction();
          isEditingActivePushMessage.value = false;
        },
        changedFields,
        quasar: $q,
      }
    );
    openActiveSubmitDialog();
  };

  const onResetAction = () => {
    onPushMessageReset();
  };

  const { openDialog: openDeleteDialog } = useMessageDeleteDialog({
    onDismiss: () => (isLoading.value = false),
    onOk: async (rationale: string | null) => {
      if (!isExistingPushMessage(pushMessage.value)) return;
      isLoading.value = true;
      try {
        await deletePushMessage(
          pushMessage.value.id,
          productLineId.value,
          rationale ?? ""
        );
      } catch (error: unknown) {
        isLoading.value = false;
        $q.notify({
          message: "Failed to delete push message",
          type: "negative",
        });
        return;
      }

      isLoading.value = false;
      router.push({ name: "PushMessagesList" });
      $q.notify({ message: "Push message has been deleted" });
    },
  });

  const onDeleteAction = () => {
    if (!isExistingPushMessage(pushMessage.value)) return;
    isLoading.value = true;
    openDeleteDialog();
  };

  const onValidateAction = async () => {
    if (!isExistingPushMessage(pushMessage.value)) {
      throw new Error("Tried to validate draft (not saved).");
    }
    if (pushMessage.value.target === null) {
      throw new Error("Tried to validate push message without target.");
    }
    isLoading.value = true;

    try {
      await requestPushMessageValidation(
        pushMessage.value.id,
        productLineId.value
      );
    } catch (error: unknown) {
      isLoading.value = false;
      $q.notify({
        type: "negative",
        message: "Failed to request message validation.",
      });
      return;
    }

    onValidationStatusChange(ValidationStatus.VALIDATING);
    await onTriggerValidation();
    await reloadPushMessage();
    isLoading.value = false;
  };

  const currentProductLine = computed(() =>
    getProductLine(productLineId.value)
  );
  const defaultProductLine = computed(() =>
    cloneProductLineOptions.value.includes(currentProductLine.value)
      ? currentProductLine.value
      : cloneProductLineOptions.value[0]
  );

  const { openDialog: openCloneDialog } = useCloneDialog({
    onOk: async ({ product, productLine }) => {
      if (!isExistingPushMessage(pushMessage.value)) {
        throw new Error("Tried to clone push message draft (not saved).");
      }

      isLoading.value = true;
      router.push({
        name: "PushMessage",
        params: {
          productLine,
          product,
        },
        query: {
          cloneFromMessageId: pushMessage.value.id,
          cloneFromProductLineId: productLineId.value,
        },
      });

      $q.notify({
        message: "Form has been filled with data of cloned message",
      });
      isLoading.value = false;
    },
    onDismiss: () => (isLoading.value = false),
    defaultProductLine,
    productLineOptions: cloneProductLineOptions,
  });

  const onCloneAction = () => {
    if (!isExistingPushMessage(pushMessage.value)) {
      throw new Error("Tried to clone push message draft (not saved).");
    }

    openCloneDialog();
  };

  const onPreviewAction = async () => {
    try {
      const { title, text, url, image } = pushMessage.value;
      const result = await pushMessagePreview(productLineId.value, {
        message: text,
        title,
        url,
        image,
      });
      $q.notify(result.message);
    } catch (error: unknown) {
      $q.notify({
        message: "Failed to preview message",
        type: "negative",
      });
    }
  };

  const { openDialog: openActivateDialog } = useMessageActivateDialog();

  const onActivateAction = async () => {
    if (!isExistingPushMessage(pushMessage.value)) return;
    const existingPushMessage = pushMessage as Ref<ExistingPushMessage>;

    const activateCallback = async () => {
      try {
        await activatePushMessage(
          productLineId.value,
          existingPushMessage.value.id
        );
      } catch (error: unknown) {
        $q.notify({
          message: "Failed to activate message: " + getErrorMessage(error),
          type: "negative",
        });
        isLoading.value = false;
        return;
      }
      await reloadPushMessage();
      isLoading.value = false;
      $q.notify("Message activated");
    };

    isLoading.value = true;
    openActivateDialog(existingPushMessage)
      .onOk(activateCallback)
      .onCancel(() => {
        isLoading.value = false;
      });
  };

  const { openDialog: openCancelDialog } = useMessageCancelDialog({
    onDismiss: () => (isLoading.value = false),
    onOk: async (rationale) => {
      if (!isExistingPushMessage(pushMessage.value)) return;
      isLoading.value = true;
      try {
        await cancelPushMessage(
          productLineId.value,
          pushMessage.value.id,
          rationale ?? ""
        );
      } catch (error: unknown) {
        isLoading.value = false;
        $q.notify({
          message: getErrorMessage(error),
          type: "negative",
        });
        await reloadPushMessage();
        return;
      }

      // Reload push message data - deliveries status will change after cancel.
      await reloadPushMessage();
      isLoading.value = false;
      $q.notify({ message: "Push message has been cancelled" });
    },
  });
  const onCancelAction = () => {
    if (!isExistingPushMessage(pushMessage.value)) {
      throw new Error("Tried to cancel non existent push message.");
    }

    isLoading.value = true;
    openCancelDialog();
  };

  const onEditActivePushMessageAction = () => {
    isEditingActivePushMessage.value = true;
  };

  return {
    onActivateAction,
    onCancelAction,
    onCloneAction,
    onDeleteAction,
    onPreviewAction,
    onResetAction,
    onSubmitAction,
    onValidateAction,
    onEditActivePushMessageAction,
    onSubmitActivePushMessageAction,
    isEditingActivePushMessage: computed(
      () => isEditingActivePushMessage.value
    ),
  };
};
